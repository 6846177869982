import { getGtmContentGroup } from '~/utils/getGtmContentGroup'

export const useGtmPageView = () => {
  const route = useRoute()
  const {
    public: { baseUrl },
  } = useRuntimeConfig()

  onMounted(() => {
    const gtm = useKftGtm()

    setTimeout(() => {
      gtm.pushToDataLayer({
        routeName: route.name,
        content_group: getGtmContentGroup(route),
        pageType: 'PageView',
        pageUrl: baseUrl + route.fullPath,
        pagePath: route.fullPath,
        pageTitle: document?.title || '',
        event: 'nuxtRoute',
      })
    }, 2500)
  })
}
